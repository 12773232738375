<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the following reaction that is at equilibrium:</p>

      <p class="mb-5 pl-8">
        <chemical-latex content="A(aq) + B(aq) <=> C(aq)" />
        <stemble-latex content="$\qquad \Delta \text{H}=-$" />
        <number-value :value="DeltaH" unit="\text{kJ/mol}" />
      </p>

      <p class="mb-n3">
        a) How would
        <stemble-latex content="$\text{Q}$" />
        and
        <stemble-latex content="$\text{K}$" />
        compare immediately following an increase in temperature?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block pl-6" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>

      <p class="mb-n3">b) How would the system respond to this increase in temperature?</p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
        <div v-for="option in options2" :key="option.value">
          <v-radio class="d-inline-block pl-6" :value="option.value" />
          <stemble-latex :content="option.condition" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question111',
  components: {ChemicalLatex, StembleLatex, NumberValue},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {
          condition: '$Q > K\\text{  because } Q \\text{ increases}$',
          value: '1',
        },
        {
          condition: '$Q > K\\text{  because } K \\text{ decreases}$',
          value: '2',
        },
        {
          condition: '$Q < K\\text{  because } Q \\text{ decreases}$',
          value: '3',
        },
        {
          condition: '$Q < K\\text{  because } K \\text{ increases}$',
          value: '4',
        },
        {
          condition: '$Q = K\\text{  because neither one changes}$',
          value: '5',
        },
      ],
      options2: [
        {condition: '$\\text{Shift left (form more reactants)}$', value: '1'},
        {condition: '$\\text{Shift right (form more products)}$', value: '2'},
        {condition: '$\\text{No change}$', value: '3'},
      ],
    };
  },
  computed: {
    DeltaH() {
      return this.taskState.getValueBySymbol('DeltaH').content;
    },
  },
};
</script>
